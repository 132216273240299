/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withPrefix, Link, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import Tips from '../../components/tips';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { checkNull } from '../../helpers/check-null';

const MortgageAffordability = (props) => {
    const { getShareLink } = withShareResults();
    const PageContent = HTMLContent || Content;
    const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    // React Redux hooks
	const dispatch = useDispatch();
    const query = useSelector(state => state.mortgageAffordability);

    useEffect(() => {
        dispatch({ type: 'APPLY_MORTGAGE_AFFORDABILITY_URL_PARAMS' });

        return () => {
            dispatch({ type: 'RESET_MORTGAGE_AFFORDABILITY_SELECTIONS' });
        };
    }, []);

    // Total number of payments (months)
    const totalPayments = checkNull(query.period) * 12;
    
    // Interest paid on loan per month
    const monthlyInterest = checkNull(query.interest) / 12 / 100;

    // Calculate maximum loan amount
    const maxBorrowAmount = checkNull(query.monthlyPayment) * (1 - Math.pow(1 + monthlyInterest, -totalPayments)) / monthlyInterest;

    // Calculate total repayable including interest
    const calcTotalRepayable = checkNull(query.monthlyPayment) * totalPayments;

    const formatCurrency = (val) => {
		let formatted = 0;
		
		if (checkNull(query.monthlyPayment) === 0 || checkNull(query.interest) === 0 || checkNull(query.period) === 0 || val < 0) {
			formatted = '0.00';
		} else {
			formatted = val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
		}
		
		return formatted;
    };

    // Calculate how much paid back per pound borrowed
	const payRate = formatCurrency(calcTotalRepayable / maxBorrowAmount);

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} />
                )}
                description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Property', url: '/property'},
                ]}
            />

            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='monthly-payment'
                                required
                                labelText='I can afford (per month)'
                                modalHeading={definitions[3].frontmatter.title}
                                modalContent={<PageContent content={definitions[3].html} />}
                                placeholder="0"
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_MORTGAGE_AFFORDABILITY_MONTHLY_PAYMENT',
                                    payload: event.target.value,
                                })}
                                value={query.monthlyPayment}
                            />

                            <InputField
                                id='interest'
                                required
                                labelText='Interest rate'
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                                placeholder="0"
                                symbol='percentage'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_MORTGAGE_AFFORDABILITY_INTEREST',
                                    payload: event.target.value,
                                })}
                                value={query.interest}
                            />

                            <InputField
                                id='period'
                                required
                                labelText='Repayment period (years)'
                                modalHeading={definitions[1].frontmatter.title}
                                modalContent={<PageContent content={definitions[1].html} />}
                                placeholder="0"
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_MORTGAGE_AFFORDABILITY_PERIOD',
                                    payload: event.target.value,
                                })}
                                value={query.period}
                            />
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>

            <Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
                <ResultListing>
                    <ResultListing.Item 
                        heading='You could afford to borrow'
                        result={`£${formatCurrency(maxBorrowAmount)}`}
                    />

                    <ResultListing.Item 
                        heading='Total repayable'
                        result={`£${formatCurrency(calcTotalRepayable)}`}
                        modalHeading={definitions[2].frontmatter.title}
                        modalContent={<PageContent content={definitions[2].html} />}
                    />

                    <ResultListing.Item 
                        heading='Amount repaid'
                        result={`£${payRate}`}
                        subheading='per £1 borrowed'
                    />
                </ResultListing>

                <div className='summary-card__controls'>
                    <ShareResults url={getShareLink(query)} />
                </div>
            </Section>

            <Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#x1F3E0;</span> Buying your next property?</h2>
					<p>Find out how much Stamp Duty you'll pay on your next property purchase with our <Link to="/property/stamp-duty">Stamp Duty Calculator</Link></p>
				</>
			)} />
        </Default>
    );
};

export default MortgageAffordability;

export const MortgageAffordabilityQuery = graphql`
    query MortgageAffordabilityQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/monthly-repayments|src/definitions/initial-interest-rate|src/definitions/mortgage-repayment-period|/src/definitions/total-repayable/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;